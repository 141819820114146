import { FC, useContext } from 'react';
import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOutsideClickDetector,
  EuiSuperSelect,
  useEuiTheme,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PhoneInput } from 'react-international-phone';
import { css } from '@emotion/css';
import 'react-international-phone/style.css';

import { SearchContext } from '@/pages/Profiler2/context';

import { Fields, schema } from './schema';

interface AddFilterProps {
  onClose: () => void;
}

const DEFAULT_VALUES: Fields = {
  type: 'phone',
  email: '',
  phone: '',
  name: '',
  birth_date: '',
  inn: '',
  snils: '',
  car_number: '',
  address: '',
  ip: '',
};

export const AddFilter: FC<AddFilterProps> = ({ onClose }) => {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { filterOptions, handleSetFilter, countries } = useContext(SearchContext);
  const { control, handleSubmit, watch } = useForm<Fields>({
    defaultValues: { ...DEFAULT_VALUES, type: filterOptions[0]?.value },
    resolver: yupResolver(schema),
  });
  const { type } = watch();

  const onSubmit = (data: Fields) => {
    const { type } = data;
    handleSetFilter({ [type]: data[type as keyof Fields] as string });
    onClose();
  };

  return (
    <EuiModal
      onClose={onClose}
      maxWidth="l"
      className={css`
        & > button {
          inset-inline-end: 20px;
          inset-block-start: 20px;
        }
      `}
    >
      <EuiOutsideClickDetector onOutsideClick={onClose}>
        <span>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              <h1
                className={css`
                  font-size: 14px;
                  font-weight: 600;
                  line-height: 18px;
                `}
              >
                {t('addFilterForm.title')}
              </h1>
            </EuiModalHeaderTitle>
          </EuiModalHeader>
          <EuiModalBody>
            <EuiForm
              component="form"
              className={css`
                width: 575px;
                max-width: 90vw;
                margin: 0 auto;
                min-height: 180px;
              `}
              onSubmit={handleSubmit(onSubmit)}
            >
              <Controller
                control={control}
                name="type"
                render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                  <EuiFormRow
                    fullWidth
                    className={css`
                      .euiSuperSelectControl.euiSuperSelect--isOpen__button,
                      .euiSuperSelectControl--compressed:focus {
                        background-image: none;
                      }
                    `}
                  >
                    <EuiSuperSelect
                      name={name}
                      valueOfSelected={value}
                      options={filterOptions}
                      itemClassName="filter-option"
                      onChange={onChange}
                      onBlur={onBlur}
                      isInvalid={!!error}
                      fullWidth
                      compressed
                    />
                  </EuiFormRow>
                )}
              />
              {filterOptions.map(({ value }) =>
                value !== type ? null : value === 'phone' ? (
                  <Controller
                    key={value}
                    control={control}
                    name="phone"
                    render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                      <EuiFormRow
                        fullWidth
                        isInvalid={!!error}
                        error={t(error?.message as string)}
                        onPaste={(event: React.ClipboardEvent) => {
                          event.preventDefault();
                          const pastedData = event.clipboardData?.getData('Text');
                          if (pastedData) {
                            const normalizedValue = pastedData.replace(/\D/g, '');
                            onChange(normalizedValue);
                          }
                        }}
                      >
                        <PhoneInput
                          name={name}
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          defaultCountry="ua"
                          className="phone-input-container"
                          preferredCountries={['ua', 'ru', 'by', 'us']}
                          countries={countries}
                        />
                      </EuiFormRow>
                    )}
                  />
                ) : (
                  <Controller
                    key={value}
                    control={control}
                    name={type as keyof Fields}
                    render={({ field: { value, name, onChange, onBlur }, fieldState: { error } }) => (
                      <EuiFormRow fullWidth isInvalid={!!error} error={t(error?.message as string)}>
                        <EuiFieldText
                          value={value}
                          onChange={onChange}
                          onBlur={onBlur}
                          fullWidth
                          placeholder={t(`addFilterForm.placeholder.${name}`)}
                          compressed
                          isInvalid={!!error}
                        />
                      </EuiFormRow>
                    )}
                  />
                )
              )}

              <EuiButton
                color="primary"
                fill
                size="s"
                type="submit"
                className={css`
                  display: block;
                  padding-inline: ${euiTheme.size.l};
                  margin: ${euiTheme.size.xxxxl} 0 0 auto;
                `}
              >
                {t('addFilterForm.button')}
              </EuiButton>
            </EuiForm>
          </EuiModalBody>
        </span>
      </EuiOutsideClickDetector>
    </EuiModal>
  );
};
