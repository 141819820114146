import moment from 'moment';
import { FC, Fragment, useCallback, useLayoutEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { EuiBadge } from '@elastic/eui';
import { css } from '@emotion/css';
import { captureException } from '@sentry/react';
import { useTranslation } from 'react-i18next';

import { CollapsedItem } from '../CollapsedItem';
import { getTrustColor } from '../LeakProfilerData';
import { LeakCountryBadge, useCountry } from '../LeakCountryBadge';

export type LeakMetaCardData =
  | (Definitions.Meta & {
      folder_tree?: string[] | undefined;
    })
  | Partial<
      {
        fields: string[];
        records: number;
        raw_records: number;
        known_affected_domains: string[];
        fields_counters: Record<string, string>;
      } & Pick<Definitions.LeakStats, 'trust' | 'country' | 'released_at'>
    >;

interface LeakMetaCardProps {
  data: LeakMetaCardData;
  id: string;
}

type DataTypes = 'object' | 'array' | 'line' | 'boolean' | 'undefined';

const COLLAPSIBLE_SIZE_ARRAY = 5;
const DATE_TIME_KEYS_FORMATED: string[] = ['datetime', 'released_at'];

const getType = (data: any): DataTypes => {
  if (typeof data === 'object' && Array.isArray(data)) {
    return 'array';
  } else if (typeof data === 'object' && data !== null) {
    return 'object';
  } else if (['number', 'string', 'bigint', 'symbol'].includes(typeof data)) {
    return 'line';
  } else if (typeof data === 'boolean') {
    return 'boolean';
  } else {
    return 'undefined';
  }
};

const objectLineTypes: DataTypes[] = ['line', 'boolean', 'undefined'];

const ARRAY_FORMATTER_KEYS = ['fields'];
const NUMBER_FORMATTER_KEYS = ['records', 'raw_records'];
const EXCLUDE_KEYS = ['fields_counters'];
interface ArrayFormatterParams {
  data: any[];
  key: string;
}

const checkCompress = (value: any) => !['object'].includes(getType(value));

export const LeakMetaCard: FC<LeakMetaCardProps> = ({ data, id }) => {
  const [offset, setOffset] = useState(190);
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);

  const { getCountry } = useCountry();

  useLayoutEffect(() => {
    if (containerRef.current) {
      const items = containerRef.current.querySelectorAll('.P__key');
      if (items.length) {
        let maxWidth = 0;
        const maxLeftPosition = Array.from(items).reduce((accum, item) => {
          const { x, width } = item.getBoundingClientRect();
          maxWidth = Math.max(width, maxWidth);
          return Math.max(x + maxWidth, accum);
        }, 0);

        const minLeftPosition = Array.from(items).reduce((accum, item) => {
          const { x } = item.getBoundingClientRect();
          return Math.min(x, accum);
        }, maxLeftPosition);

        setOffset((maxLeftPosition - minLeftPosition) * 1.25);
      }
    }
  }, [setOffset]);

  const lineFormatter = useCallback(
    (value: string, key?: string) => {
      if (typeof value === 'number') value = `${value}`;
      if (!!key && DATE_TIME_KEYS_FORMATED.includes(key)) {
        try {
          value = moment.parseZone(value).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          captureException(error);
        }
      }

      if (!!key && key === 'trust') {
        return (
          <EuiBadge
            className={cn(
              (() => {
                const trustColors = getTrustColor(value);
                if (!trustColors) return '';

                return css(`
                border-color: ${trustColors.border}!important;
                background-color: ${trustColors.background}!important;
                color: ${trustColors.text}!important;
              `);
              })(),
              'trustBadge'
            )}
            aria-label="trust"
            title={t(`tooltips.trust`, {
              trust: t(`trust.${value}`),
            })}
          >
            {value.toUpperCase()}
          </EuiBadge>
        );
      }

      if (!!key && key === 'country') {
        return <LeakCountryBadge code={value} getCountry={getCountry} />;
      }
      if (!!key && NUMBER_FORMATTER_KEYS.includes(key) && !!value) {
        return `${new Intl.NumberFormat('uk-UA').format(Number(value))}`;
      }
      return value;
    },
    [t, getCountry]
  );

  const arrayFormatter = useCallback(({ data, key }: ArrayFormatterParams) => {
    switch (key) {
      case 'fields':
        return (
          <div
            className={cn(
              'P__value',
              css(`
                  display: inline-flex;
                  flex-wrap: wrap;
                  gap: 8px;
                  margin-top: 6px;

                  & .euiBadge {
                    margin: 0;
                  }
                `)
            )}
          >
            {data.map((field, indexField) => (
              <EuiBadge key={`field-${field}-${indexField}`}>{field.trim()}</EuiBadge>
            ))}
          </div>
        );
    }
  }, []);

  const renderData = useCallback(
    (data: any, prefix: string, level: number, parrentKey: string | undefined = undefined) => {
      const type = getType(data);
      switch (type) {
        case 'object':
          return Object.entries(data).map(([key, value]) => (
            <span
              className={cn('P__line', {
                [css(`
                    & .P__value {
                      max-width: calc(100% - ${offset + 16 * level}px);
                    }
                  `)]: objectLineTypes.includes(getType(value)) || ARRAY_FORMATTER_KEYS.includes(key),
              })}
              key={`iterartion-${prefix}-${key}`}
            >
              <span
                className={cn(
                  'P__tab',
                  css(`
                    margin-left: ${16 * level}px;
                  `)
                )}
              >
                {'\t'.repeat(level)}
              </span>
              {!EXCLUDE_KEYS.includes(key) && (
                <span
                  className={cn(
                    'P__key',
                    {
                      P__key_first: level === 0,
                      P__key_first_compressed: checkCompress(value),
                    },
                    css(`
                      width: ${offset - 16 * level}px;
                    `)
                  )}
                >
                  {key}:&nbsp;
                </span>
              )}
              {renderData(value, prefix + key, level + 1, key)}
              <span className="P__br">
                <br />
              </span>
            </span>
          ));
        case 'array': {
          if (parrentKey && ARRAY_FORMATTER_KEYS.includes(parrentKey)) {
            return arrayFormatter({ data, key: parrentKey });
          } else {
            const isLineItem = data.every((value: any) => objectLineTypes.includes(getType(value)));
            return (
              <span className="P__array">
                <CollapsedItem
                  size={COLLAPSIBLE_SIZE_ARRAY}
                  data={data}
                  id={`${id}-${prefix}`}
                  margin={offset + 16 * (level + 1)}
                  render={(button, showedData) =>
                    showedData.map((value: any, index: number) => (
                      <Fragment key={`iterartion-${prefix}-${index}`}>
                        {isLineItem ? (
                          <>
                            <span
                              className={cn('P__line', {
                                [css(`
                    &>.P__value {
                      margin-left:  ${offset - 16 * (level + 1)}px;
                      max-width: calc(100% - ${offset + 16 * (level + 1)}px);
                    }
                  `)]: objectLineTypes.includes(getType(value)),
                              })}
                            >
                              <span
                                className={cn(
                                  'P__tab',
                                  css(`
                                    margin-left: ${16 * (level + 1)}px;
                                  `)
                                )}
                              >
                                {'\t'.repeat(level)}
                              </span>
                              {renderData(value, prefix + index, level, '')}
                              {!!button && index === showedData.length - 1 && (
                                <span
                                  className={cn(
                                    'P__value',
                                    css(`
                                  padding-left: ${16 * (level + 1)}px;
                                `)
                                  )}
                                >
                                  {button}
                                </span>
                              )}
                            </span>
                            <span className="P__br">
                              <br />
                            </span>
                          </>
                        ) : (
                          <>
                            {renderData(value, prefix + index, level, '')}
                            {!!button && index === showedData.length - 1 && button}
                          </>
                        )}
                      </Fragment>
                    ))
                  }
                />
              </span>
            );
          }
        }
        case 'boolean':
          return (
            <span
              className={cn('P__value', {
                P__value_first: level - 1 === 0,
              })}
            >
              {data ? 'true' : 'false'}
            </span>
          );
        case 'line':
          return (
            <span
              className={cn('P__value', {
                P__value_first: level - 1 === 0,
                P__value_badge: parrentKey && ['country', 'trust'].includes(parrentKey),
              })}
            >
              {lineFormatter(data, parrentKey)}
            </span>
          );
        default:
          return null;
      }
    },
    [lineFormatter, offset, arrayFormatter, id]
  );

  return (
    <div ref={containerRef} className="P">
      {renderData(data, id, 0)}
    </div>
  );
};
