import { FC } from 'react';

const SIZE = 128;

export const Loader: FC<{ notAuthenticated?: boolean }> = ({ notAuthenticated }) => {
  return (
    <img
      style={{
        width: SIZE,
        height: SIZE,
      }}
      src={notAuthenticated ? '/not_auth_loader.gif' : '/loader.gif'}
      alt="loader"
    />
  );
};
