import { FC, Fragment, memo, useCallback, useMemo, useState } from 'react';
import {
  EuiBadge,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiCopy,
  EuiDataGrid,
  EuiDataGridCellValueElementProps,
  EuiDataGridColumn,
  EuiDataGridRowHeightOption,
  EuiDataGridRowHeightsOptions,
  EuiDataGridStyle,
  EuiFlexGroup,
  EuiFlexItem,
  EuiHorizontalRule,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTablePagination,
  EuiToolTip,
  useEuiTheme,
} from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { captureException } from '@sentry/react';
import { css } from '@emotion/css';
import cn from 'classnames';

import { Roles, useRole, useWindowSize } from '@/hooks';
import { EmptyData } from '../Core';
import { SearchDataControls } from '../SearchData/SearchDataControls';
import { DENSITY_STAGE_KEY, RAW_HEIGHT_STAGE_KEY } from '../SearchData';
import { LeakCountryBadge, useCountry } from '../LeakCountryBadge';
import { useAppDispatch } from '@/store';
import { leaksActions } from '@/store/leaks';
import { Loader } from '../Loader';

import { useSearchData } from './useSearchData';
import { ColumnItem, Columns, SortColumn } from './types';
import { CONFIG_ITEMS_PER_PAGE } from './constants';
import { LeakLink } from './LeakLink';

interface LeakProfilerDataProps {
  activePage: number;
  limitPagination: number;
  initialSort: SortColumn | null;
  isSearched: boolean;
  onChangeItemsPerPage: (pageSize: number) => void;
  onChangePage: (pageIndex: number) => void;
  onChangeSort: (sort: Definitions.Sort) => void;
  onExport: () => void;
}

interface RowCellActionsProps {
  data: ColumnItem & {
    rest: Definitions.LeakStats;
  };
  columns: EuiDataGridColumn[];
  visibleColumns: string[];
}

const getDensity = (): EuiDataGridStyle => {
  const styles: EuiDataGridStyle = { fontSize: 'm', cellPadding: 'm' };
  try {
    const data = localStorage.getItem(DENSITY_STAGE_KEY);
    if (data) {
      const savedStyles = JSON.parse(data);
      if (savedStyles.cellPadding && savedStyles.fontSize) {
        styles.cellPadding = savedStyles.cellPadding;
        styles.fontSize = savedStyles.fontSize;
      }
    }
  } catch (e) {
    captureException(e);
  }
  return styles;
};

const getRawHeightOptions = (): undefined | EuiDataGridRowHeightOption => {
  let options: undefined | EuiDataGridRowHeightOption = 'auto';
  const data = localStorage.getItem(RAW_HEIGHT_STAGE_KEY);
  try {
    if (data) {
      options = JSON.parse(data);
    }
  } catch {
    if (data && typeof data === 'string') {
      if (data === 'auto') {
        options = data;
      } else if (data === 'undefined') {
        options = undefined;
      }
    }
  }
  return options;
};

export const getTrustColor = (trust: string) => {
  switch (trust) {
    case 'l':
      return {
        text: '#8C0000',
        background: '#FFD1C2',
        border: '#E2C8BF',
      };
    case 'm':
      return {
        text: '#997300',
        background: '#FFE596',
        border: '#F2CE62',
      };
    case 'h':
      return {
        text: '#3D7D66',
        background: '#D9F3D6',
        border: '#BCDFC2',
      };
    case 'f':
      return {
        text: '#3D7D66',
        background: '#D9F3D6',
        border: '#BCDFC2',
      };
    default:
      return undefined;
  }
};

const RowCellActions: FC<RowCellActionsProps> = ({ data: { rest, ...rawData }, columns }) => {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { t } = useTranslation();
  const copyText = useMemo(() => {
    let templateText = ``;
    columns.forEach((column) => {
      switch (column.id) {
        case Columns.released_at:
          templateText += `\n${column.displayAsText}:`;
          if (rawData[column.id]) {
            templateText += `${moment
              .parseZone(rawData[column.id] as string)
              .format(process.env.REACT_APP_DATE_FORMAT)}`;
          }
          break;
        case Columns.trust:
          templateText += `\n${column.displayAsText}: ${t(`trust.${rawData[column.id]}`)}`;
          break;
        case Columns.title:
          if (rest.country) templateText += `\ncountry: ${rest.country}`;
          templateText += `\n${column.displayAsText}: ${rawData[column.id]}`;
          break;
        case Columns.description:
          templateText += `\n${column.displayAsText}: ${rawData[column.id]}`;
          break;
        case Columns.fields:
          templateText += `\n${column.displayAsText}: ${(rawData[column.id] as string[])
            .map((field) => field.trim())
            .join(', ')}`;
          break;
        case Columns.records:
          templateText += `\n${column.displayAsText}: ${rawData[column.id]}`;
          break;
        default:
          templateText += `\n${column.displayAsText}: ${rest[column.id as keyof Definitions.LeakStats]}`;
          break;
      }
    });

    return templateText.replace(/^\n/, '');
  }, [rawData, rest, columns, t]);

  const actions = [
    <Fragment key="copy_row">
      <EuiCopy textToCopy={copyText} display="block">
        {(copy) => <EuiContextMenuItem onClick={copy}>Copy row</EuiContextMenuItem>}
      </EuiCopy>
      <EuiHorizontalRule margin="none" />
    </Fragment>,
  ];

  return (
    <EuiFlexGroup justifyContent="center">
      <EuiPopover
        isOpen={isPopoverVisible}
        panelPaddingSize="none"
        anchorPosition="upCenter"
        button={
          <EuiButtonIcon
            aria-label="Show actions"
            iconType="boxesHorizontal"
            color="text"
            onClick={() => setIsPopoverVisible(!isPopoverVisible)}
          />
        }
        closePopover={() => setIsPopoverVisible(false)}
      >
        <EuiContextMenuPanel
          css={{
            minWidth: 160,
          }}
          items={actions}
          size="s"
        />
      </EuiPopover>
    </EuiFlexGroup>
  );
};

export const LeakProfilerData = memo<LeakProfilerDataProps>(function SearchData({
  activePage,
  limitPagination,
  initialSort,
  isSearched,
  onChangeItemsPerPage,
  onChangePage,
  onChangeSort,
  onExport,
}) {
  const { t } = useTranslation();
  const { euiTheme } = useEuiTheme();
  const { getCountry } = useCountry();

  const dispatch = useAppDispatch();

  const { isValid: isCanEditLeakByRole } = useRole([Roles.SYSTEM]);

  const isCanEditLeak =
    (process.env.REACT_APP_SENTRY_ENVIRONMENT === 'dev' || process.env.NODE_ENV === 'development') &&
    isCanEditLeakByRole;

  const {
    columns,
    visibleColumns,
    sortingColumns,
    rawData,
    totalItems,
    isLoading,
    uiLimitRow,
    isExportPennding,
    handleChangeVisibleColumns,
    handleColumnResize,
    handleChangeSort,
  } = useSearchData({ initialSort, onChangeSort });
  const { height } = useWindowSize();
  const [isFullScreen, setFullscreen] = useState(false);
  const [density] = useState<EuiDataGridStyle>(getDensity());

  const handleChangeGridStyle = useCallback(({ cellPadding, fontSize }: EuiDataGridStyle) => {
    localStorage.setItem(DENSITY_STAGE_KEY, JSON.stringify({ cellPadding, fontSize }));
  }, []);

  const handleChangeRawHeight = useCallback(({ defaultHeight }: EuiDataGridRowHeightsOptions) => {
    localStorage.setItem(RAW_HEIGHT_STAGE_KEY, JSON.stringify(defaultHeight));
  }, []);

  const handleEditLeak = useCallback(
    (rowIndex: number) => {
      dispatch(leaksActions.setDetail(rawData[rowIndex].rest.id as string));
    },
    [dispatch, rawData]
  );

  const RenderCellValue = useCallback(
    ({ rowIndex, columnId }: EuiDataGridCellValueElementProps) => {
      switch (columnId) {
        case Columns.released_at:
          return (
            <span>
              {rawData[rowIndex][columnId]
                ? moment.parseZone(rawData[rowIndex][columnId] as string).format(process.env.REACT_APP_DATE_FORMAT)
                : ''}
            </span>
          );
        case Columns.trust: {
          const trust = rawData[rowIndex][columnId] as string;
          return (
            <EuiBadge
              className={cn(
                (() => {
                  const trustColors = getTrustColor(trust);
                  if (!trustColors) return '';

                  return css(`
                  border-color: ${trustColors.border}!important;
                  background-color: ${trustColors.background}!important;
                  color: ${trustColors.text}!important;
                `);
                })(),
                'trustBadge'
              )}
              aria-label="trust"
              title={t(`tooltips.trust`, {
                trust: t(`trust.${trust}`),
              })}
            >
              {trust.toUpperCase()}
            </EuiBadge>
          );
        }
        case Columns.title: {
          const title = rawData[rowIndex][columnId] as string;
          const country = rawData[rowIndex].rest.country;
          return (
            <EuiFlexGroup alignItems="flexStart" gutterSize="xs">
              {country && (
                <EuiToolTip position="top" content={<p>{country === 'WW' ? t(`country.WW`) : getCountry(country)}</p>}>
                  <LeakCountryBadge code={country} getCountry={getCountry} />
                </EuiToolTip>
              )}
              {title && <LeakLink title={title} />}
            </EuiFlexGroup>
          );
        }
        case Columns.description:
          return <span>{rawData[rowIndex][columnId]}</span>;
        case Columns.input_lines_count:
        case Columns.records: {
          const countText = new Intl.NumberFormat('uk-UA').format(Number(rawData[rowIndex][columnId]));
          return <span>{countText}</span>;
        }
        case Columns.fields:
          return (
            <EuiFlexGroup
              wrap
              alignItems="flexStart"
              gutterSize="xs"
              className={css`
                .euiBadge {
                  margin: 0px;
                }
              `}
            >
              {(rawData[rowIndex][columnId] as string[]).map((field, index) => (
                <EuiBadge key={`field-${index}`}>{field}</EuiBadge>
              ))}
              &nbsp;
            </EuiFlexGroup>
          );
        default:
          return <span>{rawData[rowIndex].rest[columnId as keyof Definitions.LeakStats]}</span>;
      }
    },
    [rawData, t, getCountry]
  );

  if (isLoading && isFullScreen) {
    return (
      <EuiPanel
        style={{
          overflow: 'hidden',
          position: 'fixed',
          zIndex: 1000000,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        hasBorder={false}
        hasShadow={false}
        paddingSize="none"
        borderRadius={'none'}
      >
        <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
          <Loader />
        </EuiFlexGroup>
      </EuiPanel>
    );
  }

  if (isLoading)
    return (
      <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
        <EuiSpacer size="xxl" />
        <Loader />
      </EuiFlexGroup>
    );

  if (!totalItems.value) return <EmptyData title={isSearched ? t('emptyData.title') : ''} />;

  return (
    <EuiPanel
      style={{
        overflow: 'hidden',
        width: '100%',
        ...(isFullScreen
          ? {
              position: 'fixed',
              zIndex: euiTheme.levels.header as number,
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
            }
          : {}),
      }}
      hasBorder={!isFullScreen}
      hasShadow={false}
      paddingSize="none"
      borderRadius={isFullScreen ? 'none' : 'm'}
    >
      {!isFullScreen && (
        <EuiFlexItem>
          <span style={{ margin: '5px 10px 5px 10px' }}>
            <strong>
              {totalItems.relation === 'gte'
                ? '> 10 000'
                : totalItems.value
                ? new Intl.NumberFormat('uk-UA').format(totalItems.value)
                : null}
            </strong>{' '}
            {t('page.passwordFinder.hits')}
          </span>
        </EuiFlexItem>
      )}
      <div>
        <EuiDataGrid
          aria-labelledby=""
          style={
            isFullScreen
              ? {
                  maxHeight: height - 34,
                  height: height - 34,
                }
              : {
                  maxHeight: Math.max(height - 320, 400),
                  height: Math.max(height - 320, 400),
                }
          }
          columns={columns}
          columnVisibility={{ visibleColumns, setVisibleColumns: handleChangeVisibleColumns }}
          sorting={{ columns: sortingColumns, onSort: handleChangeSort }}
          rowCount={uiLimitRow && rawData.length > uiLimitRow ? uiLimitRow : rawData.length}
          renderCellValue={RenderCellValue}
          rowHeightsOptions={{ defaultHeight: getRawHeightOptions(), onChange: handleChangeRawHeight }}
          leadingControlColumns={[
            {
              id: 'actions',
              width: 40,
              headerCellRender: () => null,
              rowCellRender: ({ rowIndex }) => (
                <RowCellActions visibleColumns={visibleColumns} columns={columns} data={rawData[rowIndex]} />
              ),
            },
          ]}
          toolbarVisibility={{
            showSortSelector: false,
            showFullScreenSelector: false,
            additionalControls: {
              left: {
                append: (
                  <SearchDataControls
                    limit={
                      uiLimitRow
                        ? new Intl.NumberFormat('de-DE', { maximumSignificantDigits: 3 }).format(uiLimitRow)
                        : ''
                    }
                    isDisabledExport={isExportPennding}
                    onExport={onExport}
                  />
                ),
              },
              right: (
                <EuiButtonIcon
                  iconType={isFullScreen ? 'fullScreenExit' : 'fullScreen'}
                  color="text"
                  display="empty"
                  size="xs"
                  onClick={() => {
                    setFullscreen((state) => !state);
                  }}
                />
              ),
            },
          }}
          onColumnResize={handleColumnResize}
          gridStyle={{
            ...density,
            border: 'all',
            header: 'shade',
            onChange: handleChangeGridStyle,
          }}
          trailingControlColumns={
            isCanEditLeak
              ? [
                  {
                    id: 'actions',
                    width: 50,
                    headerCellRender: () => null,
                    rowCellRender: ({ rowIndex }) => (
                      <>
                        <EuiButtonIcon
                          color={'text'}
                          iconType="pencil"
                          onClick={() => handleEditLeak(rowIndex)}
                          aria-label="Edit"
                        />
                      </>
                    ),
                  },
                ]
              : undefined
          }
        />
      </div>
      {totalItems.value && uiLimitRow && (
        <EuiTablePagination
          aria-label="Search pagination"
          pageCount={Math.ceil(Math.min(totalItems.value, uiLimitRow) / limitPagination)}
          activePage={activePage}
          showPerPageOptions={totalItems.value > limitPagination}
          onChangePage={onChangePage}
          itemsPerPage={limitPagination}
          onChangeItemsPerPage={onChangeItemsPerPage}
          itemsPerPageOptions={CONFIG_ITEMS_PER_PAGE}
        />
      )}
    </EuiPanel>
  );
});
