import {
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFlyout,
  EuiFlyoutBody,
  EuiLoadingContent,
  EuiMarkdownFormat,
  EuiSpacer,
  EuiTitle,
  useEuiTheme,
} from '@elastic/eui';
import { FC, MouseEventHandler, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { LeakMetaCard } from '../LeakMetaCard';
import { EmptyData } from '../Core';

import { useDownloads } from './useDownloads';
import { prepareData } from './helper';

interface LeakFlyoutProps {
  leak: Definitions.Leak;
  hrefAllLeak: string | undefined;
  hrefNotLeak: string | undefined;
  onShowAllLeak: MouseEventHandler<HTMLAnchorElement>;
  onShowNoLeak: MouseEventHandler<HTMLAnchorElement>;
  onClose: () => void;
}

export const LeakProfilerFlyout: FC<LeakFlyoutProps> = ({
  leak,
  hrefAllLeak,
  hrefNotLeak,
  onShowAllLeak,
  onShowNoLeak,
  onClose,
}) => {
  const { t } = useTranslation();

  const { euiTheme } = useEuiTheme();

  const { leak: detailLeak, isLoading } = useDownloads({
    id: leak?.id,
    title: leak.title || '',
  });

  const data = useMemo(() => prepareData(detailLeak), [detailLeak]);
  return (
    <EuiFlyout
      aria-label={`flyout-${leak.title}`}
      onClose={onClose}
      ownFocus
      maxWidth={700}
      maskProps={{
        headerZindexLocation: 'above',
      }}
    >
      <EuiFlyoutBody>
        <EuiTitle size="xxs">
          <h2
            style={{
              fontWeight: euiTheme.font.weight.regular,
              wordBreak: 'break-all',
              paddingRight: euiTheme.size.m,
            }}
          >
            <b>{t('modals.leak.title')}:</b> {leak.title}
          </h2>
        </EuiTitle>
        <EuiSpacer size="m" />

        {detailLeak && detailLeak.id && data && (
          <>
            <EuiFlexGroup alignItems="flexStart">
              <EuiFlexItem grow={1}>
                <EuiSpacer size="m" />
                {!!detailLeak?.description && (
                  <>
                    <EuiMarkdownFormat
                      style={{
                        wordBreak: 'break-word',
                      }}
                      color="subdued"
                      textSize="s"
                    >
                      {detailLeak.description}
                    </EuiMarkdownFormat>
                    <EuiSpacer size="m" />
                  </>
                )}

                <EuiFlexGroup gutterSize="s">
                  <EuiButtonEmpty
                    style={{ paddingInline: 0 }}
                    iconType="search"
                    contentProps={{
                      style: {
                        paddingInline: 0,
                      },
                    }}
                    onClick={onShowAllLeak}
                    href={hrefAllLeak}
                  >
                    {t('modals.leak.button.showAll')}
                  </EuiButtonEmpty>
                  <EuiButtonEmpty
                    style={{ paddingInline: 0 }}
                    iconType="eraser"
                    onClick={onShowNoLeak}
                    href={hrefNotLeak}
                  >
                    {t('modals.leak.button.showNot')}
                  </EuiButtonEmpty>
                </EuiFlexGroup>
                <EuiSpacer size="s" />
              </EuiFlexItem>
            </EuiFlexGroup>
            <LeakMetaCard data={data} id={detailLeak.id} />
          </>
        )}
        {isLoading && !detailLeak && <EuiLoadingContent lines={10} />}
        {!isLoading && !detailLeak && <EmptyData title={t('emptyData.title')} />}
      </EuiFlyoutBody>
    </EuiFlyout>
  );
};
