import { LeakMetaCardData } from '../LeakMetaCard';
import { FIELD_NAMES_EXCEPTIONS } from '../LeakProfilerData/helper';

export const prepareData = (leak: Definitions.LeakStats | null): LeakMetaCardData | null => {
  if (!leak) return null;
  const entries = Object.entries(leak).filter(([key, val]) => !!val && !FIELD_NAMES_EXCEPTIONS.includes(key));
  const result: LeakMetaCardData = {
    trust: leak.trust,
    country: leak.country,
    released_at: leak.released_at,
    raw_records: leak.input_lines_count,
    records: leak.output_lines_count,
    known_affected_domains: leak.known_affected_domains,
    fields: entries.map(([key]) => key.replaceAll('_', ' ').replace('count', '').trim()),
    fields_counters: entries.reduce((acc, [key, val]) => {
      acc[key.replaceAll('_', ' ').replace('count', '').trim()] = new Intl.NumberFormat('uk-UA').format(val);
      return acc;
    }, {} as Record<string, string>),
  };

  return Object.fromEntries(
    Object.entries(result).filter(
      ([, value]) => value && (!Array.isArray(value) || (value.length > 0 && value.some((v) => !!v)))
    )
  );
};
