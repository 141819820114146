import moment from 'moment';
import { captureException } from '@sentry/react';

import { ProfilerValidator } from '@/services/profiler-validator';
import { Matcher } from '../AutoComplete/matcher';

import { DataTypes, Files, FormattedKeys, Line, Lines, ProfilerValidatedKeys } from './types';

export const DATE_KEYS_FORMATTED = [
  'date',
  'updated_at',
  'created_at',
  'released_at',
  'birthday',
  'contact_created_date',
  'release_order',
  'date_of_issue',
  'date_of_expiry',
  'validation_date',
];

export const FILTERED_KEYS = [
  'profile_photos',
  'created_at',
  'updated_at',
  'recursive_search',
  's3_path',
  'phone_sha1',
  'ip_sha1',
];

export const FORMATTED_KEYS = [
  'emails',
  'birthday',
  'contact_created_date',
  'release_order',
  'date_of_issue',
  'date_of_expiry',
  'location',
  'timestamp',
] as const;

const FILES_FORMATED_KEYS = ['files'];

export const PROFILER_VALIDATED_KEYS = ['birthday', 'inn', 'gender', 'snils'] as const;

export const FILTERED_BY_COUNTRY_KEY: ProfilerValidatedKeys[] = ['inn', 'gender'];
export const FILTERED_BY_COUNTRY_CODE: string[] = ['ua', 'ru'];
export const COLLAPSED_ARRAY_SIZE = 4;
export const objectLineTypes: DataTypes[] = ['line', 'boolean', 'undefined'];
export const ADDRESS_RAW_KEYS = ['.addresses.raw', '.addresses.location', '.location'];
export const LINKS_KEY = ['full_url'];

export const getType = (data: any): DataTypes => {
  if (typeof data === 'object' && Array.isArray(data)) {
    return 'array';
  } else if (typeof data === 'object' && data !== null) {
    return 'object';
  } else if (['number', 'string', 'bigint', 'symbol'].includes(typeof data)) {
    return 'line';
  } else if (typeof data === 'boolean') {
    return 'boolean';
  } else {
    return 'undefined';
  }
};

const filterKeysInObject = (data: Record<string, any>, keysForFiltering: string[]) => {
  for (const key in data) {
    if (keysForFiltering.includes(key)) delete data[key];
  }
  return data;
};

export const filterData = (data: any, filteredKeys = FILTERED_KEYS): any => {
  const type = getType(data);
  if (type === 'object') {
    for (const key in data) {
      if (filteredKeys.includes(key) || data[key] === '' || data[key] === null) {
        delete data[key];
      } else if (FORMATTED_KEYS.includes(key as FormattedKeys)) {
        switch (key as FormattedKeys) {
          case 'emails': {
            if (getType(data[key]) === 'array') {
              data[key] = (data[key] as any[]).map((val) => filterKeysInObject(val, ['raw', 'email_sha1']));
            }
            break;
          }

          case 'date_of_expiry':
          case 'date_of_issue':
          case 'release_order':
          case 'contact_created_date':
          case 'birthday': {
            if (typeof data[key] === 'object') data[key] = data[key].date || data[key].raw;
            break;
          }
          case 'timestamp': {
            if (typeof data[key] === 'object') data['validation_date'] = data[key].date || data[key].raw;
            delete data[key];
            break;
          }
          case 'location':
            if (data[key].lat && data[key].lon) {
              data[key] = data[key].lat + ', ' + data[key].lon;
            } else {
              data[key] = filterData(data[key], filteredKeys);
            }
            break;
        }
      } else {
        data[key] = filterData(data[key], filteredKeys);
      }
    }
  } else if (type === 'array') {
    data = (data as any[]).map((val) => filterData(val, filteredKeys));
  }

  return data;
};

export const formatDate = (value: string) => {
  try {
    const d = moment.parseZone(value as string);
    if (d.isValid()) return d.format(process.env.REACT_APP_DATE_FORMAT);
    return value;
  } catch (e) {
    captureException(e);
    return value;
  }
};

const validate = (value: string, key: string, profilerValidatedKeys: ProfilerValidatedKeys[], inn?: string) => {
  let isValid: boolean | null = null;
  let title = '';
  switch (key as ProfilerValidatedKeys) {
    case 'birthday': {
      if (inn && profilerValidatedKeys.includes('inn')) {
        isValid = ProfilerValidator.validateBirthdayByInn(value, inn);
        title = `profilerValidate.birthday.${isValid ? 'correct' : 'failed_by_inn'}`;
      } else if (!ProfilerValidator.validateBirthday(value)) {
        isValid = false;
        title = `profilerValidate.birthday.failed`;
      }
      break;
    }
    case 'inn':
      isValid = ProfilerValidator.validateINN(value);
      title = `profilerValidate.inn.${isValid ? 'correct' : 'failed'}`;
      break;
    case 'snils':
      isValid = ProfilerValidator.validateSnils(value);
      title = `profilerValidate.snils.${isValid ? 'correct' : 'failed'}`;
      break;
    case 'gender':
      if (inn) {
        isValid = ProfilerValidator.validateSexByInn(value, inn);
        title = `profilerValidate.gender.${isValid ? 'correct' : 'failed'}`;
      }
      break;
  }
  if (isValid !== null) {
    return { isValid, title };
  }
  return null;
};

export function getLines(
  this: { inn?: string; profilerValidatedKeys: ProfilerValidatedKeys[]; dateKeysFormatted?: string[] },
  data: any,
  level: number,
  parentKey: string,
  forceCollapse = false
): Lines {
  const lines: Lines = [];
  const type = getType(data);
  const inn = this.inn;
  const profilerValidatedKeys = this.profilerValidatedKeys;
  const dateKeysFormatted = this.dateKeysFormatted || DATE_KEYS_FORMATTED;

  if (type === 'object') {
    for (const key in data) {
      if (FILES_FORMATED_KEYS.includes(key)) {
        const line: Files = {
          type: 'files',
          key,
          level,
          value: data[key] as Definitions.File[],
        };
        lines.push(line);
      } else {
        const lineType = getType(data[key]);
        const parentHash = parentKey ? `${parentKey}.${key}` : key;
        const line: Line = {
          type: 'line',
          key,
          level,
          value: '',
          parentHash,
        };
        if (objectLineTypes.includes(lineType)) {
          let value = data[key];
          let isValid;
          let title;
          if (profilerValidatedKeys.includes(key as ProfilerValidatedKeys)) {
            const validation = validate(value, key, profilerValidatedKeys, inn);
            if (validation) {
              isValid = validation.isValid;
              title = validation.title;
            }
          }
          if (lineType === 'boolean') {
            value = data[key] ? 'true' : 'false';
          } else if (typeof data[key] === 'number') {
            value = `${data[key]}`;
          } else if (dateKeysFormatted.includes(key) && data[key]) {
            value = formatDate(data[key]);
          }
          line.value = value;
          line.isValid = isValid;
          line.title = title;
          lines.push(line);
        } else {
          lines.push(line);
          lines.push(...getLines.call(this, data[key], level + 1, parentHash));
        }
      }
    }
  } else if (type === 'array') {
    (data as any[]).every((val, index, arr) => {
      const lineType = getType(val);
      let value = val;
      if (lineType === 'boolean') value = val ? 'true' : 'false';
      if (objectLineTypes.includes(lineType)) {
        lines.push({
          type: 'line',
          key: '',
          value,
          level,
          parentHash: `${parentKey}`,
        });
      } else {
        lines.push(...getLines.call(this, val, level, parentKey));
      }
      if (index !== arr.length - 1)
        lines.push({
          type: 'separator',
        });

      if (!forceCollapse && index === COLLAPSED_ARRAY_SIZE) {
        lines.push({
          type: 'collapsed',
          level,
          data: getLines.call(this, arr.slice(index, arr.length), level, parentKey, true) as Line[],
          count: arr.length - index,
          parentHash: `${parentKey}`,
        });
        return false;
      }

      return true;
    });
  }
  return lines;
}

export const getChunks = (text: string, match: string, operator?: string): string[] => {
  switch (operator) {
    case 'starts_with':
      return text.split(new RegExp(`^${Matcher.screenSpecialChars(match.toLocaleLowerCase())}`, 'i'));
    case 'ends_with':
      return text.split(new RegExp(`${Matcher.screenSpecialChars(match.toLocaleLowerCase())}$`, 'i'));
    default:
      return text.split(new RegExp(`${Matcher.screenSpecialChars(match.toLocaleLowerCase())}`, 'i'));
  }
};

export const getMatch = (text: string, value: string, operator: string): string | null => {
  const regexp = Matcher.prepareRegExp(value, operator);
  let returnedMath: string | null = null;
  switch (operator) {
    case 'not_contains': {
      break;
    }
    case 'starts_with':
    case 'ends_with':
    default: {
      const match = text.match(regexp);
      if (match && match[0]) {
        returnedMath = match[0];
      }
      break;
    }
  }

  return returnedMath;
};
