import { SchemaOf, object, string } from 'yup';
import moment from 'moment';

import { clearPhoneNumber, checkIP, checkSnilsFormat } from '@/utils';

export type Fields = Omit<Definitions.ProfilerAssessmentsRequest, 'include_leaks_info'> & {
  type: string;
};

export const schema: SchemaOf<Fields> = object().shape({
  type: string().required(),
  phone: string().when('type', {
    is: 'phone',
    then: string().test(
      'phone',
      'addFilterForm.error.phone.phone_error',
      (value) => !!value && clearPhoneNumber(value).length >= 10
    ),
  }),
  email: string().when('type', {
    is: 'email',
    then: string().email('addFilterForm.error.email.email_error').required('addFilterForm.error.required'),
  }),
  name: string().when('type', {
    is: 'name',
    then: string()
      .min(1, 'addFilterForm.error.name.min_error')
      .max(255, 'addFilterForm.error.name.max_error')
      .required('addFilterForm.error.required'),
  }),
  birth_date: string().when('type', {
    is: 'birth_date',
    then: string().test('date', 'addFilterForm.error.birth_date.birth_date_error', (value) => {
      if (value) {
        const isValidYear = /^\d{4}$/.test(value) && parseInt(value, 10) >= 1900;
        const date = moment(
          value,
          ['DD-MM-YYYY', 'DD.MM.YYYY', 'DD/MM/YYYY', 'YYYY-MM-DD', 'YYYY.MM.DD', 'YYYY/MM/DD'],
          true
        );
        const isValidDate = date.isValid() && date.isSameOrAfter('1900-01-01', 'day');
        return isValidYear || isValidDate;
      } else {
        return false;
      }
    }),
  }),
  address: string().when('type', {
    is: 'address',
    then: string()
      .min(2, 'addFilterForm.error.address.min_error')
      .max(512, 'addFilterForm.error.address.max_error')
      .test('address', 'addFilterForm.error.address.address_error', (value) => {
        const matches = value?.match(/[a-zа-яіїєё0-9]/gi);
        return !!matches && matches.length >= 2;
      })
      .required('addFilterForm.error.required'),
  }),
  snils: string().when('type', {
    is: 'snils',
    then: string().test(
      'snils',
      'addFilterForm.error.snils.snils_error',
      (value) => !!value && checkSnilsFormat(value)
    ),
  }),
  inn: string().when('type', {
    is: 'inn',
    then: string()
      .min(8, 'addFilterForm.error.inn.min_error')
      .max(16, 'addFilterForm.error.inn.max_error')
      .matches(/^\d+$/, 'addFilterForm.error.inn.inn_error')
      .required('addFilterForm.error.required'),
  }),
  car_number: string().when('type', {
    is: 'car_number',
    then: string()
      .min(4, 'addFilterForm.error.car_number.min_error')
      .max(12, 'addFilterForm.error.car_number.max_error')
      .matches(/^[a-zа-яіїєё0-9\s-]*$/i, 'addFilterForm.error.car_number.car_number_error')
      .required('addFilterForm.error.required'),
  }),
  ip: string().when('type', {
    is: 'ip',
    then: string().test('ip', 'addFilterForm.error.ip.ip_error', (value) => {
      return !!value && checkIP(value);
    }),
  }),
});
