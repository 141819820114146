import { FC } from 'react';
import { EuiFlexGroup, EuiPage, EuiPageSection, EuiPageTemplate, EuiSpacer } from '@elastic/eui';

import { Loader } from '@/components/Loader';

export const PageLoader: FC<{ notAuthenticated?: boolean }> = ({ notAuthenticated }) => {
  return (
    <EuiPageTemplate grow>
      <EuiPage direction="column" grow>
        <EuiPageSection paddingSize="none" grow alignment="center">
          <EuiFlexGroup direction="column" alignItems="center" gutterSize="none">
            <EuiSpacer size="xl" />
            <Loader notAuthenticated={notAuthenticated} />
          </EuiFlexGroup>
        </EuiPageSection>
      </EuiPage>
    </EuiPageTemplate>
  );
};
