import { ColumnItem, Columns, SortColumn } from './types';

export const FIELD_NAMES_EXCEPTIONS = [
  'id',
  'country',
  'trust',
  'type',
  'references',
  'tags',
  'known_affected_domains',
  'added_at',
  'known_threat_actors',
  'input_lines_count',
  'output_lines_count',
  'description',
  'released_at',
  'title',
];

export const getRowData = (
  items: Definitions.LeakStats[]
): (ColumnItem & {
  rest: Definitions.LeakStats;
})[] =>
  items.map(({ title, trust, released_at, description, output_lines_count, input_lines_count, ...rest }) => ({
    [Columns.trust]: trust,
    [Columns.title]: title,
    [Columns.released_at]: released_at,
    [Columns.description]: description,
    [Columns.records]: output_lines_count,
    [Columns.input_lines_count]: input_lines_count,
    [Columns.fields]: Object.entries(rest)
      .filter(([key, val]) => !!val && !FIELD_NAMES_EXCEPTIONS.includes(key.replaceAll(' ', '_')))
      .map(([key, val]) => (val ? key.replaceAll('_', ' ').replace('count', '') : '')),
    rest,
  }));

const RECORDS_SORT_FIELD = 'output_lines_count';

export const sortingUiAdapter = ({ id, direction }: SortColumn): SortColumn => {
  if ([RECORDS_SORT_FIELD].includes(id)) {
    switch (id) {
      case RECORDS_SORT_FIELD:
        id = Columns.records;
        break;
      default:
        break;
    }
  }
  return {
    direction,
    id,
  };
};
export const sortingRequestAdapter = ({ id, direction }: SortColumn): SortColumn => {
  if ([Columns.records].includes(id as Columns)) {
    switch (id) {
      case Columns.records:
        id = RECORDS_SORT_FIELD;
        break;
      default:
        break;
    }
  }
  return {
    direction,
    id,
  };
};
